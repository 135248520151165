import User from "../models/user";
import { authURL } from "../BaseUrl";
import axios from "axios";

class AuthApi {
  static async login(email: string, password: string): Promise<User> {
    axios;
    try {
      const response = await axios.post(`${authURL}/admin/login`, {
        email: email,
        password: password,
      });
      const token = localStorage.setItem("token", response.data.token);
      axios.defaults.headers.common["Authorization"] = response.data.token;
      return response.data["User"] as User; //  User.fromJson(response.data["User"]);
    } catch (err) {
      console.log(err), "error";
      throw err;
    }
  }

  static async autoLogin() {
    try {
      const token = localStorage.getItem("token");
      if (token) axios.defaults.headers.common["Authorization"] = token;

      const response = await axios.get(`${authURL}/admin/autoLogin`, {
        headers: {
          Authorization: token,
        },
      });
      if (token) {
      }
      // console.log(response);
      return response.data as User;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}

export default AuthApi;
