import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 React routes
import routes from "routes";

import SignIn from "layouts/authentication/sign-in";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav } from "context";

// Images
import brandWhite from "assets/images/Araby.ai.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { sleep } from "helpers/sleep";
import PromiseStatus from "helpers/promisestatus";
import AuthApi from "./apis/auth.ts";
import LoadingAnimation from "components/Animation/LoadingAnimation.js";

export default function App() {
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, layout, sidenavColor, transparentSidenav, whiteSidenav, darkMode } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const [promiseStatus, setPromiseStatus] = useState(PromiseStatus.INITIALIZED);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };
  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    autoLogin();
  }, []);

  const autoLogin = async () => {
    setPromiseStatus(PromiseStatus.LOADING);
    try {
      const user = await AuthApi.autoLogin();
      if (user == null) {
        throw new Error("No user found");
      }
      setPromiseStatus(PromiseStatus.SUCCESS);
      navigate("/new-dashboard");
    } catch (e) {
      setPromiseStatus(PromiseStatus.ERROR);
      navigate("/login");
    }
  };

  let uniqueKeyCounter = 0;

  const getRoutes = (allRoutes) =>
    allRoutes?.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        uniqueKeyCounter++; // Increment the counter
        return <Route exact path={route.route} element={route.component} key={`${route.route}-${uniqueKeyCounter}`} />;
      }
      p;
      return null;
    });

  function renderMainSection() {
    return (
      <>
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="AAI Admin Dashboard"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          </>
        )}
        <Routes>
          {getRoutes(routes)}
          {/* <Route path="*" element={<Navigate to="/dashboard" />} /> */}
        </Routes>
      </>
    );
  }

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {promiseStatus == PromiseStatus.INITIALIZED || promiseStatus == PromiseStatus.LOADING ? <LoadingAnimation /> : renderMainSection()}
    </ThemeProvider>
  );
}
